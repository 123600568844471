import { Card } from 'react-bootstrap';

const Variant = ({onSelectVariant}) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Variante</Card.Title>
                <Card.Subtitle>Für welche Variante möchtest du dich registrieren?</Card.Subtitle>
                <Card.Text>
                    <div style={{display:'flex', gap: '20px'}}>
                        <Card className='hovercard' style={{width:'50%'}} onClick={() => {onSelectVariant(0)}}>
                            <Card.Body>
                                <Card.Text style={{textAlign:'center'}}>
                                    Einzelvariante
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='hovercard' style={{width:'50%'}} onClick={() => {onSelectVariant(1)}}>
                            <Card.Body>
                                <Card.Text style={{textAlign:'center'}}>
                                    Als Schule
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default Variant;