import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../services/user/user";

const ProtectedRoute = ({ element }) => {
  const user = useUser();

  return user.isLoggedIn() ? element : <Navigate to="/" />;
};

export default ProtectedRoute;