import React, { createContext, useState, useContext } from 'react';

const InfoContext = createContext(null);

export const InfoProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [classes, setClasses] = useState('custom-toast-container');

    const showInfo = (message) => {
        setMessage(message);
        setClasses('custom-toast-come-in custom-toast-container');

        setTimeout(() => {
            setClasses('modal-fade-out custom-toast-container');
            setTimeout(() => {
                setMessage(null);
                setClasses('custom-toast-container');
            }, 300);
        }, 3000);
    };

    return (
        <InfoContext.Provider value={{ showInfo}}>
            {children}
            {message && (
                <div className={classes}>
                    {message}
                </div>
            )}
        </InfoContext.Provider>
    );
};

export const useInfo = () => {
    return useContext(InfoContext);
};