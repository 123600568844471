import { useNavigate } from "react-router-dom";

const useRoutingService = () => {
    let navigation = useNavigate();

    const goTo = (value) => {
        navigation(value);
    };

    return { goTo };
};

export default useRoutingService;