import { useState } from 'react';
import { Card, FloatingLabel, Form, Button, Nav } from 'react-bootstrap';
import { useUser } from '../../services/user/user';

const Register = ({ variant, onRegister }) => {
    const user = useUser();

    const [showError, setShowError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleRegisterClick = () => {
        setShowError(false);
        setDisabled(true);

        const name = variant == 1 ? document.getElementById("floatingName").value : null;
        const firstname = document.getElementById("floatingFirstname").value;
        const lastname = document.getElementById("floatingLastname").value;
        const email = document.getElementById("floatingEmail").value;
        const street = document.getElementById("floatingStreet").value;
        const plz = document.getElementById("floatingPLZ").value;
        const city = document.getElementById("floatingCity").value;
        const tel = document.getElementById("floatingTel").value;
        const url = document.getElementById("floatingURL").value;

        user.sendActivationCode(name, firstname, lastname, email, street, plz, city, tel, url).then(()=>{
            onRegister(variant, email);
        }).catch(() => {
            setShowError(true);
        }).finally(() => {
            setTimeout(() => {
                setDisabled(false);
            }, 5000);
        });
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Registrierung</Card.Title>
                <Card.Subtitle>Gib' deine Daten und regestriere dich!</Card.Subtitle>
                <Card.Text>
                    {variant == 1 && (
                        <FloatingLabel controlId="floatingName" label="Name der Schule" className="mb-3">
                            <Form.Control type="text" placeholder="Name der Schule" />
                        </FloatingLabel>
                    )}
                    <FloatingLabel controlId="floatingFirstname" label="Vorname*" className="mb-3">
                        <Form.Control type="text" placeholder="Vorname" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingLastname" label="Nachname*" className="mb-3">
                        <Form.Control type="text" placeholder="Nachname" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingEmail" label="E-Mail*" className="mb-3">
                        <Form.Control type="text" placeholder="E-Mail" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingStreet" label="Straße*" className="mb-3">
                        <Form.Control type="text" placeholder="Straße" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPLZ" label="PLZ*" className="mb-3">
                        <Form.Control type="text" placeholder="PLZ" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingCity" label="Stadt*" className="mb-3">
                        <Form.Control type="text" placeholder="Stadt" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingTel" label="Telefon" className="mb-3">
                        <Form.Control type="text" placeholder="Telefon" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingURL" label="Webseite" className="mb-3">
                        <Form.Control type="text" placeholder="Webseite" />
                    </FloatingLabel>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Button disabled={user.isLoading() || disabled} variant="primary" onClick={handleRegisterClick}>Registrieren</Button>
                    </div>
                </Card.Text>
                {showError && (
                    <Card.Text style={{color:'red'}}>Bei der Registrierung ist ein Fehler aufgetaucht. Bitte versuche es mit anderen Daten oder später erneut.</Card.Text>
                )}
            </Card.Body>
        </Card>
    );
};

export default Register;