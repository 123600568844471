import React, { createContext, useState, useContext } from 'react';

import useApiService from "../api/api";

import Login from '../../components/Login/Login';
import TwoFactor from '../../components/TwoFactor/TwoFactor';

import { CgClose } from 'react-icons/cg';
import Register from '../../components/Register/Register';
import Variant from '../../components/Register/Variant';
import Password from '../../components/Register/Password';
import Abo from '../../components/Register/Abo';
import { useInfo } from '../info/info';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const info = useInfo();
    const api = useApiService();

    const getUserFromStorage = () =>{
        const storageUser = JSON.parse(localStorage.getItem('user'));

        if(storageUser){
            if((Date.now() - storageUser.time) >= 86400000){
                localStorage.removeItem('user');
            }else{
                return storageUser;
            }
        }

        return null;
    }

    const [user, setUser] = useState(getUserFromStorage());
    
    const [registerUser, setRegisterUser] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalFadeOutClass, setModalFadeOutClass] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);   
    };

    const closeModal = () => {
        return new Promise((resolve, reject) => {
            setModalFadeOutClass("modal-fade-out");
            setTimeout(() => {
                setIsModalOpen(false);
                setModalContent(null);
                setModalFadeOutClass(null);
                resolve(true);
            }, 300);
        });
    };
    
    const login = (login, password) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("email", login);
            formData.append("password", password);

            api.postDataFormData("login", formData).then((data) => {
                if(data == true){
                    openTwoFactorModal("Anmelden", "Es wurde ein Anmeldecode an deine E-Mail-Adresse geschickt. Gib diesen Code ein und melde dich an.", login, 1, () => {
                        resolve(true);
                    });
                }else{
                    reject(false);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    };

    const twoFactor = (login, code) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();

            formData.append("email", login);
            formData.append("activationCode", code);

            api.postDataFormData("activationCode", formData).then((data) => {
                if(data === false){
                    setUser(null);
                    reject(false);
                }else{
                    data.time = Date.now();

                    console.log(data);

                    setUser(data);
                    localStorage.setItem('user', JSON.stringify(data));

                    closeModal();

                    info.showInfo("Erfolgreich angemeldet.");

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    }

    const sendActivationCode = (name, firstname, lastname, email, street, plz, city, tel, url) =>{
        return new Promise((resolve, reject) => {
            setRegisterUser({
                "name":name,
                "contactFirstName":firstname,
                "contactLastName":lastname,
                "email":email,
                "street":street,
                "plz":plz,
                "city":city,
                "tel":tel,
                "url":url
            });
    
            const formData = new FormData();
            formData.append("email", email);
    
            api.postDataFormData("sendActivationCode", formData).then((data) => {
                if(data === true || data.trim() === "true"){
                    resolve(true);
                }else{
                    reject(false);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });
        });
    }

    const register = (code) =>{
        return new Promise((resolve, reject) => {
            api.postDataJSON("register", registerUser, {activationCode:code}).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    data.time = Date.now();
                    
                    setUser(data);
                    localStorage.setItem('user', JSON.stringify(data));

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                    reject(false);
            });;
        });
    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            setUser(null);
            localStorage.removeItem('user');

            info.showInfo("Erfolgreich abgemeldet!");

            resolve(true);
        });
    }

    const changePassword = (password) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("userId", user.id);
            formData.append("password", password);
    
            api.postDataFormData("user/changePassword", formData).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });

        });
    }

    const changeUser = (firstname, lastname) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("userId", user.id);
            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
    
            api.postDataFormData("user/update", formData).then((data) => {
                if(data === false){
                    reject(false);
                }else{
                    user.firstname = firstname;
                    user.lastname = lastname;

                    resolve(true);
                }
            }).catch((error) => {
                console.log("Error: " + error);
                reject(false);
            });

        });
    }

    const isLoggedIn = () => {
        return user !== null;
    };

    const isLoading = () => {
        return api.loading;
    }

    const getUser = () => {
        return user;
    }

    const openLoginModal = () => {
        closeModal().then(() => {
            openModal(<Login />);
        });
    }

    const openTwoFactorModal = (title, text, login, type, callback) => {
        closeModal().then(() => {
            openModal(<TwoFactor title={title} text={text} login={login} type={type} callback={callback} />);
        });
    }

    const openRegister = () => {
        closeModal().then(() => {
            openModal(<Variant onSelectVariant={handleVariantClick} />);
        });
    }

    const handleVariantClick = (v) => {
        closeModal().then(() => {
            openModal(<Register variant={v} onRegister={handleOnRegister} />);
        });
    }

    const handleOnRegister = (v, login) => {
        if(login){
            openTwoFactorModal("Registrierung", "Es wurde ein Registrierungscode an deine E-Mail-Adresse geschickt. Gib diesen Code ein und registriere dich an.", login, 2, () => {
                closeModal().then(() => {
                    openModal(<Password variant={v} onSetPassword={openAbo} />);
                });
            });
        }
    }

    const openAbo = (v = (getUser().school.name != null ? 1 : 0)) => {
        closeModal().then(() => {
            openModal(<Abo variant={v} onSelectAbo={handleAboSelection} />);
        });
    }

    const handleAboSelection = () => {
        closeModal();
    }

    const setUserData = (data) => {
        setUser(data);
    }

    return (
        <UserContext.Provider value={{ isLoggedIn, login, twoFactor, logout, openLoginModal, openRegister, isLoading, getUser, sendActivationCode, register, changePassword, changeUser, openAbo, setUserData }}>
          {children}
          {isModalOpen && (
                <div className={modalFadeOutClass ? "modal-overlay " + modalFadeOutClass : "modal-overlay"}>
                    <div className="modal-content">
                        <button className='modal-button' onClick={closeModal}><CgClose/></button>
                        {modalContent}
                    </div>
                </div>
            )}
        </UserContext.Provider>
    );
}

export const useUser = () => {
    return useContext(UserContext);
};