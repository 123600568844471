import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import "./FromTo.css";

const FromTo = ({ onChanged }) => {
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);

    const handleFrom = (n) => {
        setFrom(parseInt(n.target.value));

        if(from > 0 && to > 0){
            onChanged(from, to);
        }
    };

    const handleTo = (n) => {
        setTo(parseInt(n.target.value));
        
        if(from > 0 && to > 0){
            onChanged(from, to);
        }
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Wähle einen Bereich aus</Card.Title>
                <Card.Text>
                    <Form.Label>Von ({from})</Form.Label>
                    <Form.Range onChange={handleFrom} onClick={handleFrom} />
                </Card.Text>
                <Card.Text>
                    <Form.Label>Bis ({to})</Form.Label>
                    <Form.Range onChange={handleTo} onClick={handleTo} />
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default FromTo;