import * as React from 'react';
import "./Quiz.css";
import { Row, Card } from 'react-bootstrap';

const Quiz = () => {
  return (
    <Row>
        <Card>
            <Card.Body>
                <Card.Title>Quiz-Aufgaben</Card.Title>
                <Card.Text>
                Testen Sie Wissen und Spaß mit unseren Quiz-Aufgaben! Auf SchoolAI können Sie interaktive und spannende Quizfragen erstellen, die perfekt für den Unterricht oder zur Wiederholung des Gelernten geeignet sind. Unsere KI hilft Ihnen dabei, abwechslungsreiche Fragen zu entwickeln, die auf verschiedene Themen und Schwierigkeitsgrade abgestimmt sind. Ob Multiple-Choice-Fragen oder Wissensspiele – unsere Quiz-Aufgaben fördern das Lernen auf spielerische Weise und motivieren Ihre Schüler, sich aktiv mit dem Stoff auseinanderzusetzen. Entdecken Sie die Welt der Quiz-Aufgaben und bringen Sie neuen Schwung in Ihre Unterrichtsstunden!                </Card.Text>
            </Card.Body>
        </Card>
    </Row>
  );
};

export default Quiz;