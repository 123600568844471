import { useState } from 'react';
import { Card, FloatingLabel, Form, Button, Nav } from 'react-bootstrap';
import { useUser } from '../../services/user/user';

const Password = ( { variant, onSetPassword } ) => {
    const user = useUser();

    const [showError, setShowError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleSetPasswortClick = () => {
        setShowError(false);
        setDisabled(true);

        const password = document.getElementById("floatingPassword").value;
        const passwordConfirm = document.getElementById("floatingPasswordConfirm").value;

        if(password == passwordConfirm && password !== ""){
            user.changePassword(password).then(() => {
                onSetPassword(variant);
            }).catch(() => {
                setShowError(true);
            }).finally(() => {
                setTimeout(() => {
                    setDisabled(false);
                }, 5000);
            });
        }else{
            setShowError(true);
        }
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Registrierung</Card.Title>
                <Card.Subtitle>Du musst noch ein Passwort vergeben:</Card.Subtitle>
                <Card.Text>
                    <FloatingLabel controlId="floatingPassword" label="Passwort" className="mb-3">
                        <Form.Control type="password" placeholder="Passwort" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPasswordConfirm" label="Passwort wiederholen" className="mb-3">
                        <Form.Control type="password" placeholder="Passwort wiederholen" />
                    </FloatingLabel>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Button disabled={user.isLoading() || disabled} variant="primary" onClick={handleSetPasswortClick}>Speichern</Button>
                    </div>
                </Card.Text>
                {showError && (
                    <Card.Text style={{color:'red'}}>Beide eingetragenen Passwörter müssen übereinstimmen.</Card.Text>
                )}
            </Card.Body>
        </Card>
    );
};

export default Password;