import "./User.css";
import { Row, Card, FloatingLabel, Form, Button, ListGroup } from 'react-bootstrap';
import { useUser } from '../../services/user/user';
import { useState } from "react";
import { useInfo } from "../../services/info/info";
import Abo from "../../components/Register/Abo";

const User = () => {
  const user = useUser();
  const info = useInfo();

  const [showErrorPassword, setShowErrorPassword] = useState(false);
  const [showErrorUser, setShowErrorUser] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [firstname, setFirstname] = useState(user.getUser().firstname);
  const [lastname, setLastname] = useState(user.getUser().lastname);

  const handleSaveUser = () => {
    setShowErrorUser(false);
    setDisabled(true);

    const firstname = document.getElementById("floatingFirstname").value;
    const lastname = document.getElementById("floatingLastname").value;

    user.changeUser(firstname, lastname).then(() => {
      info.showInfo("Benutzer erfolgreich gespeichert!");
    }).catch(() => {
      setShowErrorUser(true);
    }).finally(() => {
        setTimeout(() => {
            setDisabled(false);
        }, 5000);
    });
  }

  const handleSetPassword = () => {
    setShowErrorPassword(false);
    setDisabled(true);

    const password = document.getElementById("floatingPassword").value;
    const passwordConfirm = document.getElementById("floatingPasswordConfirm").value;

    if(password == passwordConfirm && password !== ""){
        user.changePassword(password).then(() => {
          document.getElementById("floatingPassword").value = "";
          document.getElementById("floatingPasswordConfirm").value = "";

          info.showInfo("Passwort erfolgreich geändert!");
        }).catch(() => {
          setShowErrorPassword(true);
        }).finally(() => {
            setTimeout(() => {
                setDisabled(false);
            }, 5000);
        });
    }else{
      setShowErrorPassword(true);
    }
  }

  const handeAboClick = () => {
    user.openAbo();
  }

  return (
    <>
      <Row>
        <Card>
            <Card.Body>
                <Card.Title>Hallo {user.getUser().firstname}!</Card.Title>
                <Card.Text>Hier kannst du deine Benuzterdaten bearbeiten oder dein Passwort ändern.</Card.Text>
                {user.getUser().school && (
                  <Card.Text>
                    <p><b>Daten deiner Schule:</b></p>
                    <p>{user.getUser().school.name}</p>
                    <p>{user.getUser().school.contactFirstName} {user.getUser().school.contactLastName}</p>
                    <p>{user.getUser().school.street}</p>
                    <p>{user.getUser().school.plz} {user.getUser().school.city}</p>
                  </Card.Text>
                )}
            </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Card.Title>Benutzerdaten</Card.Title>
            <Card.Text>
              <FloatingLabel label="E-Mail" className="mb-3">
                  <Form.Control disabled={true} type="text" placeholder="E-Mail" value={user.getUser().email} />
              </FloatingLabel>
              <FloatingLabel controlId="floatingFirstname" label="Vorname" className="mb-3">
                  <Form.Control type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)}  />
              </FloatingLabel>
              <FloatingLabel controlId="floatingLastname" label="Nachname" className="mb-3">
                  <Form.Control type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
              </FloatingLabel>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                  <Button disabled={disabled} variant="primary" onClick={handleSaveUser}>Speichern</Button>
              </div>
            </Card.Text>
            {showErrorUser && (
              <Card.Text style={{color:'red'}}>Fehler beim Speichern der Benutzerdaten.</Card.Text>
            )}
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Card.Title>Passwort</Card.Title>
            <Card.Text>
              <FloatingLabel controlId="floatingPassword" label="Passwort" className="mb-3">
                  <Form.Control type="password" placeholder="Passwort" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPasswordConfirm" label="Passwort wiederholen" className="mb-3">
                  <Form.Control type="password" placeholder="Passwort wiederholen" />
              </FloatingLabel>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                  <Button disabled={disabled} variant="primary" onClick={handleSetPassword}>Speichern</Button>
              </div>
            </Card.Text>
            {showErrorPassword && (
              <Card.Text style={{color:'red'}}>Beide eingetragenen Passwörter müssen übereinstimmen.</Card.Text>
            )}
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Card.Title>Lizenz</Card.Title>
                <Card.Text>
                {user.getUser().school.licence && user.getUser().school.licence.active && (
                  <>
                    Dein Benutzer hat eine aktive Lizensierung.
                  </>
                )}
                {!user.getUser().school.licence || !user.getUser().school.licence.active && (
                  <Button variant="primary" onClick={handeAboClick}>Abo abschließen</Button>
                )}
                </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default User;