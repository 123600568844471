import React, { useState, useEffect } from 'react';
import './Footer.css';
import useRoutingService from '../../services/routing/routing';
import Nav from 'react-bootstrap/Nav';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const routing = useRoutingService();

  const [footerBG, setFooterBG] = useState('');

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if(currentPath == "/"){
      setFooterBG("white-footer");
    }else{
      setFooterBG("grey-footer");
    }
  }, [location]);

  return (
     <footer className={footerBG}>
        <div className='copyright'>
          © Konzept-iX Software GmbH 2024
        </div>
        <div className='links'>
          <Nav.Link onClick={() => {routing.goTo('/contact')}}>Kontakt</Nav.Link>
         <Nav.Link onClick={() => {routing.goTo('/imprint')}}>Impressum</Nav.Link>
         <Nav.Link onClick={() => {routing.goTo('/dataprotection')}}>Datenschutz</Nav.Link>
        </div>
     </footer>
  );
};

export default Footer;