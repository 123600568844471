import React, { useState } from 'react';
import { Form, Card, ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import "./GeneratingText.css";
import useApiService from '../../services/api/api';
import useConfig from '../../services/utils/config';
import Loading from '../Loading/Loading';

const GeneratingText = ({ language, niveau, loading, onChange, onChanged, setLoading, maxSentenceWarning, maxSentenceWarningMessage }) => {
  const api = useApiService();
  const config = useConfig();

  const [ownText, setOwnText] = useState('');
  const [generatedText, setGeneratedText] = useState('');
  const [topicSelection, setTopicSelection] = useState(config.themes[0]);
  const [topicText, setTopicText] = useState('');
  const [countSentences, setCountSentences] = useState(1);

  const [isCheckedTheme, setIsCheckedTheme] = useState(false);
  const [isCheckedOwn, setIsCheckedOwn] = useState(false);

  const [internLoading, setInternLoading] = useState(false);

  const isLoading = () => {
    return internLoading || loading;
  }

  const handleLoading = (b) => {
    setInternLoading(b);
    setLoading(b);
  }

  const handleTopic = (t) => {
      if(!isCheckedTheme && !isCheckedOwn){
          setTopicSelection(t.target.value);
          setTopicText('')
      }else if(!isCheckedOwn){
          setTopicSelection('');
          setTopicText(t.target.value);
      }   
  }

  const handleOwnText = (e) => {
        onChange();

        setOwnText(e.target.value);
        onChanged(e.target.value);

        setCountSentences(e.target.value.split(". ").length);

        setGeneratedText("");
  };

  const handleCheckboxChangeOwn = (event) => {
      setIsCheckedOwn(event.target.checked);
  };

  const handleCheckboxChangeTheme = (event) => {
      setIsCheckedTheme(event.target.checked);
  };

  const handleCountSentences = (n) => {
      if(isNaN(n)){
          setCountSentences(n.target.value);
      }else{
          setCountSentences(n);
      }
  };

  const onGenerateClick = (event) => {
    handleLoading(true);

    setGeneratedText("");

    onChange();

    var json = {
        "niveau": niveau,
        "language": language,
        "topic": topicSelection == '' ? topicText : topicSelection,
        "sentenceCount": countSentences
    }

    api.postDataJSON("language/createTextAI", json).then((data) => {
        data = data.replace("Ich habe folgenden Text:", "");
        data = data.replace(/\n/g, "");

        setGeneratedText(data);
        onChanged(data);

        setOwnText("");

        handleLoading(false);
    }).catch(() => {
        handleLoading(false);
    });
  };

  let itemsSentences = [];
  let optionsSentences = [];
  for (let number = 1; number <= 20; number++) {
      itemsSentences.push(
          <ToggleButton id={"btn_s_"+number} variant="outline-primary" value={number} onClick={() => handleCountSentences(number)}>{number}</ToggleButton>
      );
      
      optionsSentences.push(
          <option value={number}>{number}</option>
      );
  }
  
  return (
    <Card>
      <Card.Body>
          <Card.Title>Generierung</Card.Title>
          <Card.Text>
              <Form.Check // prettier-ignore
                  type="switch"
                  label="Eigener Text"
                  checked={isCheckedOwn}
                  onChange={handleCheckboxChangeOwn}
              />
                {!isCheckedOwn && (
                  <div style={{marginTop:'10px'}}>
                      <Card.Text>
                          <Form.Check // prettier-ignore
                              type="switch"
                              id="theme"
                              label="Eigenes Thema"
                              checked={isCheckedTheme}
                              onChange={handleCheckboxChangeTheme}
                          />
                          {!isCheckedTheme && (
                              <div style={{marginTop:'10px'}}>
                                  <Form.Label><b>Thema</b></Form.Label>
                                  <Form.Select value={topicSelection} size="sm" onChange={handleTopic}>
                                      {config.themes.map((item) => (
                                          <option value={item}>{item}</option>
                                      ))}
                                  </Form.Select>
                              </div>
                          )}
                          {isCheckedTheme && (
                              <div>
                                  <Form.Label><b>Thema (100 Zeichen)</b></Form.Label>
                                  <Form.Control
                                      type="text"
                                      value={topicText}
                                      onChange={handleTopic}
                                  />
                              </div>
                          )}
                      </Card.Text>
                      <Card.Text>
                          <Form.Label><b>Anzahl Sätze</b></Form.Label>
                          <ToggleButtonGroup className="sentencesToggle" type="radio" name="sentences" defaultValue={-1}>
                              {itemsSentences}
                          </ToggleButtonGroup>
                          <Form.Select className="sentencesSelect" value={countSentences} size="sm" onChange={handleCountSentences}>
                              {optionsSentences}
                          </Form.Select>
                      </Card.Text>
                      <Card.Text>
                          {!isLoading() && (
                              <Button variant="primary" onClick={onGenerateClick}>Text Generieren</Button>
                          )}
                          {isLoading() && (
                              <Loading word="Einen Moment bitte ..." />
                          )}
                      </Card.Text>
                      {generatedText !== '' && (
                          <Card.Text>
                              {generatedText}
                          </Card.Text>
                      )}
                  </div>
              )}
              {isCheckedOwn && (
                  <div>
                      <Form.Label><b>Eigener Text</b></Form.Label>
                      <Form.Control 
                          value={ownText} 
                          as="textarea" 
                          style={{ height: '200px' }}        
                          onChange={handleOwnText} 
                      />
                  </div>
              )}
            {maxSentenceWarning < countSentences && maxSentenceWarning > -1 && (
                <Card.Text style={{color:'orange', marginTop:'10px'}}>
                    {maxSentenceWarningMessage}
                </Card.Text>
            )}
          </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default GeneratingText;