import React from 'react';
import './Loading.css';

const Loading = ({word}) => {
  return (
    <div className="loading-container">
        <div className="loading-text">
          {word.split('').map((item, index) => (
            <span style={{animationDelay: (((index) / 10) * 0.5)+"s" }}>{item}</span>
          ))}
        </div>
    </div>
  );
};

export default Loading;