import useConfig from "./config";

const useUtilsService = () => {
    const config = useConfig();
    
    const getValues = (text) => {
        // Erzeuge eine Liste um die gefundenen Wörter zu speichern
        let woerter = [];
        let match;
    
        // Suche nach allen Übereinstimmungen im Text
        while ((match = config.regexp.exec(text)) !== null) {
            woerter.push(match[1].replace(/\$/g, ""));
        }
    
        for (let i = woerter.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [woerter[i], woerter[j]] = [woerter[j], woerter[i]];
        }
    
        return woerter;
    }

    const fadeIn = (id) => {
        const element = document.getElementById(id);

        if(element){
            element.classList.remove("hidden");
            element.classList.add("showCard");

            if(id !== "gen"){
                setTimeout(() => {
                    element.scrollIntoView({behavior:"smooth"});
                }, 50);
            }   
        }
    };

    const fadeOut = (id) => {
        const element = document.getElementById(id);

        if(element){
            element.classList.add("hidden");
            element.classList.remove("showCard");
        }
    };

    return { getValues, fadeIn, fadeOut };
}

export default useUtilsService;