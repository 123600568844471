import { useState } from 'react'
import useConfig from '../utils/config'
import axios from 'axios';

const useApiService = () => {
    const config = useConfig();
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const postDataJSON = (endpoint, data, params = {}) => {
        setLoading(true);
        setError(null);

        return new Promise((resolve, reject) => {
            axios.post(config.API_URL + "/" + endpoint, data, {
                params:params,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                setError(error);
                reject(error);
            })
            .finally(() => {
                setLoading(false);
            });
        });
    };

    const postDataFormData = (endpoint, data) => {
        setLoading(true);
        setError(null);

        return new Promise((resolve, reject) => {
            axios.post(config.API_URL + "/" + endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                setError(error);
                reject(error);
            })
            .finally(() => {
                setLoading(false);
            });
        });
    };

    const getData = (endpoint) => {
        setLoading(true);
        setError(null);

        return new Promise((resolve, reject) => {
            axios.get(config.API_URL + "/" + endpoint, {
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                setError(error);
                reject(error);
            })
            .finally(() => {
                setLoading(false);
            });
        });
    };

    const createPDF = (body) => {
        setLoading(true);
        setError(null);

        return new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append('html', body);

                const response = await fetch(config.API_URL + "/asset/createPDF", {
                    method: 'POST',
                    body: formData
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
        
                const blob = await response.blob(); // Hier war ein Fehler, await wurde nicht verwendet
                const url = window.URL.createObjectURL(blob);
        
                // Öffnet das PDF in einem neuen Tab
                window.open(url);
        
                resolve(true);
            } catch (error) {
                console.error('Error fetching PDF:', error);
                reject(error);
            } finally {
                setLoading(false);
            }
        });
    };
      
    return { postDataJSON, postDataFormData, createPDF, getData, loading, error };
  };
  
  export default useApiService;