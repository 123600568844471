import * as React from 'react';
import './fonts/all.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home'
import Tasks from './pages/Tasks/Tasks'
import Contact from './pages/Contact/Contact'
import Deutsch from './pages/Tasks/Deutsch/Deutsch'
import Clozetext from './pages/Tasks/Deutsch/Clozetext/Clozetext'
import Reading from './pages/Tasks/Deutsch/Reading/Reading'
import Math from './pages/Tasks/Math/Math'
import Addition from './pages/Tasks/Math/Addition/Addition'
import Quiz from './pages/Tasks/Quiz/Quiz'
import Imprint from './pages/Imprint/Imprint'
import Dataprotection from './pages/Dataprotection/Dataprotection'
import GeneratePDF from './pages/GeneratePDF/GeneratePDF'
import Substraction from './pages/Tasks/Math/Subtraction/Subtraction';
import Multipication from './pages/Tasks/Math/Multipication/Multipication';
import Division from './pages/Tasks/Math/Division/Division';
import ScrollToTop from './services/utils/scrollToTop';
import { UserProvider } from "./services/user/user";
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import User from './pages/User/User';
import { InfoProvider } from './services/info/info';

const App = () => {
  return (
    <Router>
      <InfoProvider>
        <UserProvider>
          <ScrollToTop />
          <Header />
          <div className ="bodycontainer">
            <div className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/dataprotection" element={<Dataprotection />} />
                <Route path="/tasks/deutsch" element={<Deutsch />} />
                <Route path="/tasks/deutsch/clozetext" element={<Clozetext />} />
                <Route path="/tasks/deutsch/reading" element={<Reading />} />
                <Route path="/tasks/math" element={<Math />} />
                <Route path="/tasks/math/addition" element={<Addition />} />
                <Route path="/tasks/math/subtraction" element={<Substraction />} />
                <Route path="/tasks/math/multiplication" element={<Multipication />} />
                <Route path="/tasks/math/divison" element={<Division />} />
                <Route path="/tasks/quiz" element={<Quiz />} />
                <Route
                  path="/pdf"
                  element={
                    <ProtectedRoute
                      element={<GeneratePDF />}
                    />
                  }
                />
                <Route
                  path="/user"
                  element={
                    <ProtectedRoute
                      element={<User />}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
          <Footer />
        </UserProvider>
      </InfoProvider>
    </Router>
  );
};

export default App;