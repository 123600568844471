import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Card, ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import "./Clozetext.css";
import useApiService from '../../../../services/api/api';
import { Dropdown } from 'react-bootstrap'; 
import useRoutingService from '../../../../services/routing/routing';
import useUtilsService from '../../../../services/utils/utils'; 
import useConfig from '../../../../services/utils/config';
import GeneratingText from '../../../../components/GeneratingText/GeneratingText';
import Loading from '../../../../components/Loading/Loading';
import Niveau from '../../../../components/Niveau/Niveau';
import { useUser } from '../../../../services/user/user';

const Clozetext = () => {
    const user = useUser();
    const routing = useRoutingService();
    const api = useApiService();
    const config = useConfig();
    const utils = useUtilsService();

    const [niveau, setNiveau] = useState("-1");
    const [language, setLanguage] = useState("-1");
    const [type, setType] = useState("-1");
    const [countGaps, setCountGaps] = useState(1);
    const [representation, setRepresentation] = useState("1");

    const [loading, setLoading] = useState(false);

    const [theText, setTheText] = useState('');

    const [result, setResult] = useState('');
    const [words, setWords] = useState([]);

    const resultRef = useRef(null);

    const handleNiveau = (n) => {
        setNiveau(n);
        utils.fadeIn("gen");
    };

    const handleLanguage = (l) => {
        setLanguage(l.target.value);
    }

    const handleType = (t) => {
        setType(t.target.value);
        utils.fadeIn("gaps");
    }

    const handleRepresentation = (r) => {
        setRepresentation(r.target.value);
    }

    const handleTheTextChange = () => {
        utils.fadeOut("type");
        utils.fadeOut("gaps");
        utils.fadeOut("create");
        utils.fadeOut("clozetextResult");

        setType("-1");
        setCountGaps(1);
    }

    const handleTheTextChanged = (newText) => {
        setTheText(newText);

        utils.fadeIn("type");
    }

    const handleCountGaps = (n) => {
        if(isNaN(n)){
            setCountGaps(n.target.value);
        }else{
            setCountGaps(n);
        }

        utils.fadeIn("create");
    };

    const handleGeneratePDFClick = (event) => {
        generatePDF(false);
    }

    const handleGeneratePDFAndSaveClick = (event) => {
        generatePDF(true);
    }
    
    const handleSaveClick = (event) => {
        saveText();
    }

    const handleCreateClick = (event) => {
        if(theText != ""){
            setLoading(true);

            var json = {
                "text": escapeQuotes(theText),
                "type": type,
                "gapCount": countGaps
            }
    
            api.postDataJSON("language/createClozetext", json).then((data) => {
                data = data.replace("Ich habe folgenden Text:", "");
                data = data.replace(/\n/g, "");

                data = data.trim();

                if (data.startsWith("'") && data.endsWith("'")) {
                    data = data.slice(1, -1);
                }

                setWords(utils.getValues(data));
                setResult(data);

                setLoading(false);

                utils.fadeIn("clozetextResult");
            }).catch(() => {
                setLoading(false);
            });;
        } 

        function escapeQuotes(text){
            return text.replace(/\"/g, "'");
        }
    };

    const showResult = () => {
        let showResult = "";

        if(result != ""){
            let index = 0;

            switch(representation){
                case "1" || 1:
                    showResult = result.replace(config.regexp, (match, word) => {
                        index++;
                        return '<span data-original-content=\"'+word+'\" data-index=\"'+index+'\" class=\"gap word\">' + word + '</span>';
                    });
                break;

                case "2" || 2:
                    showResult = (result.replace(config.regexp, (match, word) => {
                        index++;
                        return '<span data-original-content=\"'+word+'\" data-index=\"'+index+'\" class=\"horizontal word\">' + word + '</span>';
                    }));
                break;

                case "3" || 3:
                    showResult = (result.replace(config.regexp, (match, word) => {
                        index++;
                        return '<span data-original-content=\"'+word+'\" data-index=\"'+index+'\" class=\"vertikal word\">' + word + '</span>';
                    }));
                break;

                case "4" || 4:
                    showResult = (result.replace(config.regexp, (match, word) => {
                        index++;
                        return '<span data-original-content=\"'+word+'\" data-index=\"'+index+'\" class=\"halbiert word\">' + word + '</span>';
                    }));
                break;
            }
        }

        return showResult;
    }

    const saveText = () => {
        const htmlText = "<div class=\"clozetext\">"+showResult()+"</div>";

        var json = {
            taskHTML: htmlText,
            taskPLAIN: result,
            typeid: 1,
            subjectid: 1,
            niveauid: parseInt(niveau),
            userid: user.getUser().id
        };

        api.postDataJSON("task/save", json).then(() => {
            routing.goTo("/pdf");
        });
    }

    const generatePDF = (save) => {
        const htmlText = "<div class=\"clozetext\">"+showResult()+"</div>";

        var json = {
            taskHTML: htmlText,
            taskPLAIN: result,
            typeid: 1,
            subjectid: 1,
            niveauid: parseInt(niveau),
            userid: user.getUser().id
        };

        if(save){
            api.postDataJSON("task/save", json).then(() => {
                const htmlTextPDF = "<div class=\"task\"><div class=\"clozetext\">"+showResult()+"</div><div class=\"words\">"+words.join(", ")+"</div></div>";
    
                api.createPDF(htmlTextPDF).then(() => {
                    routing.goTo("/pdf");
                });
            });
        }else{
            const htmlTextPDF = "<div class=\"task\"><div class=\"clozetext\">"+showResult()+"</div><div class=\"words\">"+words.join(", ")+"</div></div>";
            api.createPDF(htmlTextPDF);
        }
    }

    const SafeHTMLComponent = ({ html }) => {
        const [idx, setIndex] = useState(-1);
        const [theWord, setTheWord] = useState({});
        const [dropdownRemoveStyle, setDropdownRemoveStyle] = useState({});
        const dropdownRemoveRef = useRef(null);
        const [dropdownAddStyle, setDropdownAddStyle] = useState({});
        const dropdownAddRef = useRef(null);

        const dropdownRemoveFunction = () => {
            let count = 0;

            // Function to replace only the nth match
            const replacer = (match, p1) => {
                count++;
                return count === idx ? p1 : match;
            };

            // Replace the nth occurrence of the pattern

            var newResult = result.replace(config.regexp, replacer);

            setWords(utils.getValues(newResult));
            setResult(newResult);

            setDropdownRemoveStyle({});
            setDropdownAddStyle({});
        }

        const dropdownAddFunction = () => {
            if(theWord){
                let newResult = result;

                newResult = cleanMarkedWords(newResult.substring(0, theWord.start) + "$" + theWord.word + "$" + newResult.substring(theWord.start));

                setWords(utils.getValues(newResult));
                setResult(newResult);

                setTheWord({});
            }

            setDropdownRemoveStyle({});
            setDropdownAddStyle({});
        }

        useEffect(() => {
            const handleClick = (event) => {
                setDropdownRemoveStyle({});
                setDropdownAddStyle({});

                if (event.target.dataset.index) {
                    // Container-Div berechnen
                    const containerRect = resultRef.current.getBoundingClientRect();

                    const top = event.clientY - containerRect.top;
                    const left = event.clientX- containerRect.left;

                    setDropdownRemoveStyle({
                        position: 'absolute',
                        top: `${top}px`,
                        left: `${left}px`,
                    });

                    setIndex(parseInt(event.target.dataset.index));
                }else if(event.target.classList.contains("result")){
                    // Erhalte die Range an der Klickposition
                    const range = document.caretRangeFromPoint(event.clientX, event.clientY);
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);

                    const word = getWordAtCaret(range.startContainer, range.startOffset);
                    const offsets = getOffsets(range.startContainer, range.startOffset, word.length);

                    setTheWord({start:offsets.start, end:offsets.end, word:word});

                    // Container-Div berechnen
                    const containerRect = resultRef.current.getBoundingClientRect();

                    const top = event.clientY - containerRect.top;
                    const left = event.clientX- containerRect.left;

                    setDropdownAddStyle({
                        position: 'absolute',
                        top: `${top}px`,
                        left: `${left}px`,
                    });
                }
            };

            const handleHover = (event) => {
                let showWords = document.getElementsByClassName("showWord");
                if(showWords.length > 0){
                    Array.prototype.forEach.call(showWords, (element) => {
                        if(element != event.target){
                            element.classList.remove("showWord");

                            if(representation == 1){
                                //element.innerHTML = ('_'.repeat(element.innerHTML .length));
                            }
                        }
                    });
                }

                if (event.target.dataset.index) {
                    event.target.innerHTML = event.target.dataset.originalContent;
                    event.target.classList.add("showWord");
                }
            };
        
            document.body.addEventListener('click', handleClick);
            document.body.addEventListener('mousemove', handleHover);

            return () => {
                document.body.removeEventListener('click', handleClick);
                document.body.removeEventListener('mousemove', handleHover);
            }
        }, []);
        
        return  (
            <div>
                <div className='result' dangerouslySetInnerHTML={{ __html: html }} />
                <div ref={dropdownRemoveRef} style={dropdownRemoveStyle}>
                    <Dropdown show={!!dropdownRemoveStyle.top}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={dropdownRemoveFunction}>Entfernen</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div ref={dropdownAddRef} style={dropdownAddStyle}>
                    <Dropdown show={!!dropdownAddStyle.top}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={dropdownAddFunction}>Hinzufügen</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );

        function getWordAtCaret(node, offset) {
            const text = node.textContent;
            let start = offset;
            let end = offset;
    
            // Suche nach dem Anfang des Wortes
            while (start > 0 && /\S/.test(text[start - 1])) {
              start--;
            }
    
            // Suche nach dem Ende des Wortes
            while (end < text.length && /\S/.test(text[end])) {
              end++;
            }
    
            return text.slice(start, end);
          }

          function getOffsets(node, start, end){
            var s = start;

            var sibling = node.previousSibling;

            while(sibling){
                if(sibling.nodeName == "SPAN"){
                    s = s + 2;
                }

                s = s + sibling.textContent.length;

                sibling = sibling.previousSibling;
            }

            return {start:s, end:s+end};
          }

          function cleanMarkedWords(text) {
            return text.replace(/\b[^$\s]*\$[^$\s]*\$[^$\s]*\b/g, match => {
                // Entferne alle Zeichen, die vor und nach dem Wort innerhalb der $-Zeichen stehen
                let result = match.substring(match.indexOf("$"));
                result = result.substring(0, result.lastIndexOf("$") + 1);
                return result.replace(/[^a-zA-Z\s$]+/g, '');
            });
        }
    };

    let itemsGaps = [];
    let optionsGaps = [];
    for (let number = 1; number <= 25; number++) {
        itemsGaps.push(
            <ToggleButton id={"btn_g_"+number}  variant="outline-primary" value={number} onClick={() => handleCountGaps(number)}>{number}</ToggleButton>
        );

        optionsGaps.push(
            <option value={number}>{number}</option>
        );
    }

    return (
        <div>
            <div>
                <Row>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lückentexte</Card.Title>
                            <Card.Text>
                                Lückentexte sind Texte, in denen Wörter oder Phrasen bewusst weggelassen werden und vom Lernenden ergänzt werden müssen. Diese Übungstexte sind darauf ausgelegt, die Sprachkenntnisse zu verbessern, indem fehlende Wörter oder Phrasen ergänzt werden müssen.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
            <div id="niveau" className='showCard'>
                <Row>
                    <Niveau
                        niveau={niveau}
                        onChanged={handleNiveau} 
                    />
                </Row>
            </div>
            <div id="language" className='hidden'>
                <Row>
                    <Card>
                        <Card.Body>
                            <Card.Title>Wähle ein Fach aus</Card.Title>
                            <Card.Text>
                                <Form.Select value={language} size="sm" onChange={handleLanguage} disabled>
                                    <option value="-1">Bitte Wählen</option>
                                    <option value="1">Deutsch</option>
                                </Form.Select>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
            <div id="gen" className='hidden'>
                <Row>
                    <GeneratingText
                        language={language}
                        niveau={niveau}
                        loading={loading}
                        onChange={handleTheTextChange}
                        onChanged={handleTheTextChanged} 
                        setLoading={setLoading}
                        maxSentenceWarning={-1}
                        maxSentenceWarningMessage={""}
                    />
                </Row>
            </div>
            <div id="type" className='hidden'>
                <Row>
                    <Card>
                        <Card.Body>
                            <Card.Title>Art des Lückentextes</Card.Title>
                            <Card.Text>
                                <Form.Select value={type} size="sm" onChange={handleType}>
                                    <option value="-1">Bitte Wählen</option>
                                    <option value="1">Substantive Weglassen</option>
                                    <option value="3">Verben Weglassen</option>
                                    <option value="4">Adjektive Weglassen</option>
                                </Form.Select>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
            <div id="gaps" className='hidden'>
                <Row>
                    <Card>
                        <Card.Body>
                            <Card.Title>Maximale Anzahl an Lücken</Card.Title>
                            <Card.Text>
                                <ToggleButtonGroup className="gapsToggle" type="radio" name="gaps" defaultValue={-1}>
                                    {itemsGaps}
                                </ToggleButtonGroup>
                                <Form.Select className="gapsSelect" value={countGaps} size="sm" onChange={handleCountGaps}>
                                    {optionsGaps}
                                </Form.Select>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
            <div id="create" className='hidden'>
                <Row>
                    <Card>
                        <Card.Body>
                            <Card.Title>Generierung</Card.Title>
                            <Card.Text>
                                Die Generierung kann ein wenig dauern. Das Ergebnis kann kontrolliert und nachbearbeitet werden. Zum Schluss kann eine PDF generiert werden.
                            </Card.Text>
                            <Card.Text>
                                {!loading && (
                                    <Button variant="primary" onClick={handleCreateClick}>Lückentext Erstellen</Button>
                                )}
                                {loading && (
                                    <Loading word="Einen Moment bitte ..." />
                                )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </div>
            <div id="clozetextResult" className='hidden'>
                <Row>
                    {showResult() !== '' && (
                        <Card ref={resultRef}>
                            <Card.Body>
                                <Card.Title>Ergebnis</Card.Title>
                                <Card.Text>
                                    <Form.Label>Darstellung des Lückentextes</Form.Label>
                                    <Form.Select value={representation} size="sm" onChange={handleRepresentation}>
                                        <option value="1">Lücke</option>
                                        <option value="2">Vertikal Gespiegelt</option>
                                        <option value="3">Horizontal Gespiegelt</option>
                                        <option value="4">Halbiert</option>
                                    </Form.Select>
                                </Card.Text>
                                <Card.Text>
                                    Gesetzte Lücken können mit einem Mausklick entfernt werden, bei einem Klick auf ein anderes Wort, kann dieses hinzugefügt werden.
                                </Card.Text>
                                <Card.Text>
                                    <div>
                                        <Card.Text>
                                            <SafeHTMLComponent html={showResult()} />
                                        </Card.Text>
                                        <Card.Text>
                                            {words.join(", ")}
                                        </Card.Text>
                                    </div>
                                </Card.Text>
                                {user.isLoggedIn() && user.getUser().school.licence.active && (
                                    <Card.Text>
                                        {!loading && (
                                            <div>
                                                <Button disabled={api.loading} variant="primary" onClick={handleSaveClick}>Speichern</Button>
                                                <Button disabled={api.loading} variant="primary" onClick={handleGeneratePDFClick} style={{marginLeft: '10px'}}>PDF generieren</Button>
                                                <Button disabled={api.loading} variant="primary" onClick={handleGeneratePDFAndSaveClick} style={{marginLeft: '10px'}}>Speichern und PDF generieren</Button>
                                            </div>
                                        )}
                                        {loading && (
                                            <Loading word="Einen Moment bitte ..." />
                                        )}
                                    </Card.Text>
                                )}
                                {!user.isLoggedIn() && (
                                    <div>
                                        <Card.Text>
                                            Für die Generierung von PDFs ist eine Anmeldung erforderlich.
                                        </Card.Text>
                                        <Card.Text>
                                            <Button variant="primary" onClick={() => {user.openRegister()}} style={{marginRight:'10px'}}>Registrieren</Button>
                                            <Button variant="primary" onClick={() => {user.openLoginModal()}}>Anmelden</Button>
                                        </Card.Text>
                                    </div>
                                )}
                                {user.isLoggedIn() && !user.getUser().school.licence.active && (
                                    <div>
                                        <Card.Text>
                                            Für die Generierung von PDFs ist eine aktive Lizenzierung erforderlich.
                                        </Card.Text>
                                        <Card.Text>
                                            <Button variant="primary" onClick={() => {user.openAbo()}} style={{marginRight:'10px'}}>Registrieren</Button>
                                        </Card.Text>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    )}
                </Row>
            </div>
        </div>

    );
};

export default Clozetext;