import * as React from 'react';
import "./Math.css";
import { Row, Card, Col } from 'react-bootstrap';
import useRoutingService from '../../../services/routing/routing';


const Math = () => {
  const routing = useRoutingService();

  return (
    <div>
    <Row>
      <Col>
        <Card>
            <Card.Body>
                <Card.Title>Mathe</Card.Title>
                <Card.Text>
                   Wähle zwischen Addition, Subtraktion, Multiplikation und Division aus und erstelle eine gewisse Anzahl von Aufgaben aus.
                </Card.Text>
            </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row>   
        <Col className="showCard">
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/math/addition')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Addition</Card.Title>
                    <Card.Text>
                      Aufgaben zum Addieren
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col className="showCard" style={{animationDelay:"0.2s"}}>
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/math/subtraction')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Subktraktion</Card.Title>
                    <Card.Text>
                        Aufgaben zum Subtrahieren
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    </Row>
    <Row>   
        <Col className="showCard" style={{animationDelay:"0.4s"}}>
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/math/multiplication')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Multiplikation</Card.Title>
                    <Card.Text>
                        Aufgaben zum Multiplizieren
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col className="showCard" style={{animationDelay:"0.6s"}}>
            <Card className='hovercard' onClick={() => {routing.goTo('/tasks/math/divison')}} style={{cursor:'pointer'}}>
                <Card.Body>
                    <Card.Title>Division</Card.Title>
                    <Card.Text>
                        Aufgaben zum Dividieren
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    </Row>
    </div>
  );
};

export default Math;